<template>
  <div class="team-members">
    <PropertiesMemberTransferDialog
      ref="propertiesMemberTransferDialogRef"
      @reload-data="fetchTeamMembers"
    />
    <p
      v-if="authUser.role === Role.ACCOUNT_OWNER"
      class="text-dark font-weight-bold mb-0 mt-3"
      :class="$viewport.lt.md ? 'pr-3 pl-3 m-0 mt-3' : ''"
    >
      Add Members
    </p>
    <p
      class="font-13 mt-1 text-gray-dark"
      :class="$viewport.lt.md ? 'pr-3 pl-3 m-0 mt-3' : ''"
    >
      Account owners will be able to manage other users' permissions.
    </p>
    <ElForm
      v-if="authUser.role === Role.ACCOUNT_OWNER"
      ref="form"
      :disabled="formState.loading"
      :model="newMemberPayload"
      :rules="rulesRef"
      class="mb-4 row"
      :class="$viewport.lt.md ? 'p-3' : ''"
      @submit.native.prevent="inviteNewTeamMember"
    >
      <div class="col-12 d-none d-md-block">
        <ElFormItem prop="email">
          <ElInput
            v-model="newMemberPayload.email"
            placeholder="Email"
            class="input-with-select-and-btn"
          >
            <ElSelect
              slot="prepend"
              v-model="newMemberPayload.role"
              placeholder="Select"
            >
              <ElOption
                v-for="item in newMemberOptionsRef"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </ElSelect>
            <ElTooltip
              slot="append"
              :visible-arrow="false"
              popper-class="popover-panel outside-right"
              placement="right-start"
              :disabled="!isBasicPlan"
            >
              <template
                v-if="isBasicPlan"
                slot="content"
              >
                <i class="sdicon-info-circle" />
                <div>This feature is only available in our Standard plan.</div>
                <div>
                  <a
                    target="_blank"
                    href="/billing?modify=true"
                  >
                    Learn more
                  </a>
                </div>
              </template>
              <span>
                <ElButton
                  :disabled="!newMemberPayload.email"
                  type="primary"
                  @click="inviteNewTeamMember"
                >
                  Invite
                </ElButton>
              </span>
            </ElTooltip>
          </ElInput>
        </ElFormItem>
      </div>
      <div class="col-12 d-md-none">
        <ElFormItem prop="email">
          <SdFloatLabel>
            <ElInput
              v-model="newMemberPayload.email"
              :class="$viewport.lt.md ? 'font-15' : ''"
              placeholder="Please input email"
            />
          </SdFloatLabel>
        </ElFormItem>
      </div>
      <div class="col-12 d-md-none">
        <ElFormItem prop="role">
          <SdFloatLabel label="Member role">
            <ElSelect
              v-model="newMemberPayload.role"
              placeholder="Select"
            >
              <ElOption
                v-for="item in newMemberOptionsRef"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </ElSelect>
          </SdFloatLabel>
        </ElFormItem>
      </div>
      <div class="col-12 d-md-none">
        <ElButton
          :disabled="!newMemberPayload.email"
          type="primary"
          class="w-100"
          size="medium"
          @click="inviteNewTeamMember"
        >
          Invite Member
        </ElButton>
      </div>
    </ElForm>
    <div :class="$viewport.gt.md ? '' : 'col-12'">
      <ElTable
        v-loading="formState.loading"
        :data="teamMembersRef"
        :show-header="false"
        class="font-15"
        empty-text="Loading data..."
      >
        <ElTableColumn :min-width="$viewport.lt.md ? '150' : '230'">
          <template slot-scope="scope">
            <span>{{ scope.row.first_name ? scope.row.full_name : scope.row.email }}</span>
          </template>
        </ElTableColumn>
        <ElTableColumn min-width="150">
          <div
            slot-scope="scope"
            class="text-right"
          >
            <span
              v-if="!scope.row.verified && $viewport.gt.md"
              class="text-primary pr-3"
            >Pending</span>
            <span>
              {{ getFormattedRoleName(scope.row.role) }}
            </span>
          </div>
        </ElTableColumn>
        <ElTableColumn
          v-if="authUser.role === Role.ACCOUNT_OWNER"
          width="45"
        >
          <template slot-scope="scope">
            <ElDropdown
              v-if="!scope.row.verified || canPromote(scope.row) || canDemote(scope.row) || canRemove(scope.row)"
              trigger="click"
              hide-on-click
              class="pointer"
            >
              <div>
                <i class="sdicon-more-vert font-21" />
              </div>
              <ElDropdownMenu>
                <ElDropdownItem
                  v-if="canPromote(scope.row)"
                  @click.native="showChangeRoleDialog(scope.row, Role.ACCOUNT_OWNER)"
                >
                  <a>Account owner</a>
                </ElDropdownItem>
                <ElDropdownItem
                  v-if="canDemote(scope.row)"
                  @click.native="showChangeRoleDialog(scope.row, Role.MEMBER)"
                >
                  <a>Member</a>
                </ElDropdownItem>
                <ElDropdownItem
                  v-if="!scope.row.verified"
                  class="text-danger"
                  @click.native="removeUser(scope.row)"
                >
                  Cancel invitation
                </ElDropdownItem>
                <ElDropdownItem
                  v-if="!scope.row.verified"
                  @click.native="resendInvitation(scope.row.id)"
                >
                  Resend invitation
                </ElDropdownItem>
                <ElDropdownItem
                  v-if="canRemove(scope.row)"
                  class="text-danger"
                  @click.native="removeUser(scope.row)"
                >
                  Remove user
                </ElDropdownItem>
              </ElDropdownMenu>
            </ElDropdown>
          </template>
        </ElTableColumn>
      </ElTable>
    </div>
    <ChangeRoleDialog
      v-if="changeRoleDialogState.payload.user && changeRoleDialogState.payload.role"
      :user="changeRoleDialogState.payload.user"
      :new-role-name="changeRoleDialogState.payload.role"
      :visible.sync="changeRoleDialogState.isActive"
      @close="changeRoleDialogState.isActive = false"
      @changeRole="changeRole"
    />
  </div>
</template>

<script>
import { reactive, ref } from '@vue/composition-api';

import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import Role from '@/constants/Role';
import Plan from '@/constants/Plan';
import regex from '@/constants/Regex';
import PropertiesMemberTransferDialog from '@/components/property/PropertiesMemberTransferDialog';
import SdFloatLabel from '../common/form/SdFloatLabel';

import ChangeRoleDialog from './ChangeRoleDialog';

export default {
  name: 'TeamMembersTable',
  components: {
    SdFloatLabel,
    ChangeRoleDialog,
    PropertiesMemberTransferDialog,
  },
  setup(props, context) {
    const propertiesMemberTransferDialogRef = ref(null);
    const teamMembersRef = ref([]);
    const rulesRef = ref(formRules);
    const newMemberOptionsRef = ref(defaultNewMemberOptions);
    const formState = reactive({
      loading: false,
    });
    const changeRoleDialogState = reactive(defaultChangeRoleDialogState);
    const newMemberPayload = reactive(defaultNewMemberPayload);
    const store = context.root.$store;
    const plan = store.getters['Auth/plan'];
    const isBasicPlan = plan.name === Plan.BASIC;
    const authUser = store.state.Auth.user;

    fetchTeamMembers();

    return {
      rulesRef,
      newMemberOptionsRef,
      formState,
      changeRoleDialogState,
      authUser,
      fetchTeamMembers,
      inviteNewTeamMember,
      resendInvitation,
      removeUser,
      changeRole,
      canPromote,
      canDemote,
      canRemove,
      newMemberPayload,
      teamMembersRef,
      getFormattedRoleName,
      showChangeRoleDialog,
      Role,
      isBasicPlan,
      propertiesMemberTransferDialogRef,
    };

    async function fetchTeamMembers() {
      teamMembersRef.value = await context.root.$store.dispatch('Auth/getTeamMembers');
    }

    async function inviteNewTeamMember() {
      formState.loading = true;
      try {
        await context.refs.form.validate();
        if (newMemberPayload.role === Role.ACCOUNT_OWNER) {
          await context.root.$store.dispatch('Business/addNewAccountOwner', newMemberPayload);
        } else {
          await context.root.$store.dispatch('Business/addNewTeamMember', newMemberPayload);
        }
        await fetchTeamMembers();
        newMemberPayload.email = null;
        formState.loading = false;
        showSuccessNotify(context, 'New Member invited');
      } catch (error) {
        formState.loading = false;
        if (!(error instanceof Error)) {
          return; // Validator failed
        }
        showErrorNotify(context, error.message);
      }
    }

    async function resendInvitation(id) {
      formState.loading = true;
      try {
        await context.root.$store.dispatch('Business/sendInvitationEmail', id);
        await fetchTeamMembers();
        formState.loading = false;
        showSuccessNotify(context, 'Invitation mail sent successfully');
      } catch (error) {
        formState.loading = false;
        showErrorNotify(context, error.message);
      }
    }

    async function removeUser(user) {
      formState.loading = true;
      try {
        await context.root.$store.dispatch('Business/removeMember', {
          userId: user.id,
        });
        await fetchTeamMembers();
        formState.loading = false;
        const message = user.verified
          ? 'User invitation revoked successfully'
          : 'User removed successfully';
        showSuccessNotify(context, message);
      } catch (error) {
        formState.loading = false;
        propertiesMemberTransferDialogRef.value.open(user, error.response.data.managed_properties);
      }
    }

    async function showChangeRoleDialog(user, role) {
      changeRoleDialogState.payload.user = user;
      changeRoleDialogState.payload.role = role;
      changeRoleDialogState.isActive = true;
    }

    async function changeRole() {
      formState.loading = true;
      try {
        await context.root.$store.dispatch('Role/changeRole', {
          id: changeRoleDialogState.payload.user.id,
          role: changeRoleDialogState.payload.role,
        });
        await fetchTeamMembers();
        formState.loading = false;
        changeRoleDialogState.isActive = false;
        showSuccessNotify(context, 'User role changed successfully');
      } catch (error) {
        formState.loading = false;
        changeRoleDialogState.isActive = false;
        showErrorNotify(context, error.message);
      }
    }

    function canPromote(user) {
      return user.verified
        && user.role === Role.MEMBER
        && user.id !== authUser.id;
    }

    function canDemote(user) {
      return user.verified
        && user.role === Role.ACCOUNT_OWNER
        && user.countAccountOwners >= 2
        && user.id !== authUser.id;
    }

    function canRemove(user) {
      return user.verified
        && user.id !== authUser.id;
    }

    function getFormattedRoleName(roleName) {
      return roleName.charAt(0).toUpperCase() + roleName.split('-').join(' ').substr(1);
    }
  },
};

function emailValidator(rule, value, callback) {
  if (value === '' || value === null) {
    callback();
  } else if (!value.match(regex.email)) {
    callback(new Error(rule.message));
  } else {
    callback();
  }
}

const formRules = {
  role: [
    {
      required: true,
      type: 'string',
      message: 'Role name is required',
    },
  ],
  email: [
    {
      required: true,
      type: 'email',
      trigger: 'change',
      message: 'Email is required',
      validator: emailValidator,
    },
  ],
};
const defaultNewMemberOptions = [
  {
    label: 'Member',
    value: 'member',
  },
  {
    label: 'Account Owner',
    value: 'account-owner',
  },
];
const defaultNewMemberPayload = {
  email: null,
  role: 'member',
};

const defaultChangeRoleDialogState = {
  isActive: false,
  payload: {
    user: null,
    role: null,
  },
};
</script>
<style lang="scss">
.team-members {
  .el-table__body-wrapper {
    border: none;
  }

  .el-table {
    .el-table__row:last-child {
      td {
        border-bottom: 1px solid transparent;
      }
    }

    &:before {
      display: none;
    }
  }
}
</style>
